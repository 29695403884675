import { Props } from './types';

const defaultProps: Props = {
  source: null,
  startDate: null,
  showPlaceHolderImage: false,
  placeHolderImage: null,
  controlsEnabled: true
};

export default defaultProps;
