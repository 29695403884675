/**
 *
 * Player
 */

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

// 3d parties
import dayjs from "dayjs";
import screenfull from "screenfull";

// components
import UI from "./UI";
import AudioPlaceholder from "./AudioPlaceholder";
import Unmute from "./Unmute";

// styles
import { Wrapper } from "./styles";
import "./UI/icons/style.css";

// utils
import isFacebookProvider from "../../utils/is-facebook-provider";
import isYoutubeProvider from "../../utils/is-youtube-provider";

// data, types, props, default
import { initOptionsState } from "./data";
import { OnProgressType, Props } from "./types";
import defaultProps from "./defaultProps";

function Player({
  source,
  startDate,
  showPlaceHolderImage,
  placeHolderImage,
  controlsEnabled,
}: Props) {
  // options state
  const [optionsState, setOptionsState] =
    useState<ReactPlayerProps>(initOptionsState);

  // player state
  const [playerState, setPlayerState] = useState<ReactPlayer>();

  // fullscreenFallbackEnabled enabled state
  const [fullscreenFallbackEnabled, setFullscreenFallbackEnabled] =
    useState<boolean>(false);

  // wrapper ref
  const wrapperElement = useRef<HTMLInputElement>(null);

  // init
  useEffect(() => {
    if (playerState) {
      optionsInitUpdate();
    }
  }, [playerState]);

  // watch source - update url
  useEffect(() => {
    updateOptionsState({
      url: source && source.length > 0 ? source : undefined,
      playing:
        source && isYoutubeProvider(source) && window.innerWidth < 375
          ? false
          : true,
      muted:
        source && isYoutubeProvider(source) && window.innerWidth < 375
          ? false
          : true,
      controls:
        source && source.length > 0
          ? isFacebookProvider(source) ||
            isYoutubeProvider(source) ||
            source?.includes("rumble")
          : false,
    });
  }, [source]);

  // options init update
  const optionsInitUpdate = () => {
    const localStorageVolume = localStorage.getItem("volume");

    const updatedOptions = {
      ...optionsState,
      volume:
        localStorageVolume !== null && parseFloat(localStorageVolume) !== 0
          ? parseFloat(localStorageVolume)
          : 0.7,
    };

    // console.log('updated options: ', updatedOptions);

    setOptionsState(updatedOptions);
  };

  // update options state
  const updateOptionsState = (newOptions: ReactPlayerProps) => {
    const updatedOptions = {
      ...optionsState,
      ...newOptions,
    };
    setOptionsState(updatedOptions);

    // console.log('updated options: ', updatedOptions);
  };

  // handle ready
  const handleReady = () => {
    // console.log('onReady');
  };

  // handle start
  const handleStart = () => {
    // console.log('onStart');

    if (startDate && playerState) {
      const now = dayjs();
      const startTime = dayjs(startDate);
      const seek = now.diff(startTime, "seconds");

      playerState.seekTo(seek, "seconds");
    }
  };

  // handle play pause
  const handlePlayPause = () => {
    // console.log('play pause: ', optionsState.playing);

    updateOptionsState({
      playing: !optionsState.playing,
    });
  };

  // handle volume change
  const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    localStorage.setItem("volume", e.target.value);

    // console.log('volume change: ', value);

    updateOptionsState({
      muted: !value,
      volume: value,
    });
  };

  // handle toggle muted
  const handleToggleMuted = () => {
    const localStorageVolume = localStorage.getItem("volume");
    const newLocalStorageVolume =
      localStorageVolume !== null && parseFloat(localStorageVolume) !== 0
        ? parseFloat(localStorageVolume)
        : 0.7;

    localStorage.setItem("volume", newLocalStorageVolume.toString());

    // console.log('toggle muted: ', newLocalStorageVolume);

    console.log(optionsState.muted);

    updateOptionsState({
      muted: !optionsState.muted,
      volume: !optionsState.muted ? 0 : newLocalStorageVolume,
    });
  };

  // handle play
  const handlePlay = () => {
    // console.log('onPlay');

    updateOptionsState({
      playing: true,
    });
  };

  // handle seek
  const handleSeek = () => {
    // console.log('onSeek');
  };

  // handle pause
  const handlePause = () => {
    // console.log('onPause');

    updateOptionsState({
      playing: false,
    });
  };

  // handle seek mouse down
  const handleSeekMouseDown = () => {
    updateOptionsState({
      seeking: true,
    });
  };

  // handle seek change
  const handleSeekChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateOptionsState({
      played: parseFloat(e.target.value),
    });
  };

  // handle seek mouse up
  const handleSeekMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    updateOptionsState({
      seeking: false,
    });
    playerState && playerState.seekTo(parseFloat(target.value));
  };

  // handle progress
  const handleProgress = (state: OnProgressType) => {
    const internalPlayerOptions: ReactPlayerProps = {};

    // We only want to update time slider if we are not currently seeking
    if (!optionsState.seeking) {
      updateOptionsState({
        loaded: state.loaded,
        loadedSeconds: state.loadedSeconds,
        played: state.played,
        playedSeconds: state.playedSeconds,
        ...internalPlayerOptions,
      });
    }
  };

  // handle ended
  const handleEnded = () => {
    // console.log('onEnded');
    const playerRootId = wrapperElement?.current?.closest(
      ".STRIMM_PLAYER_ROOT"
    )?.id;

    const event = new CustomEvent("STRIMM_PLAYER_EVENT_TRACK_ENDED", {
      detail: {
        playerRootId,
        source,
      },
      bubbles: true,
    });

    if (wrapperElement?.current) {
      wrapperElement.current.dispatchEvent(event);
    }

    // setEndedState(true);
  };

  // handle duration
  const handleDuration = (duration: number) => {
    // console.log('onDuration', duration);

    updateOptionsState({
      duration,
    });
  };

  // unmute click handler
  const unmuteClickHandler = () => {
    // console.log('unmute click handler');

    handleToggleMuted();
  };

  // handle click fullscreen
  const handleClickFullscreen = () => {
    if (wrapperElement && wrapperElement.current) {
      if (screenfull.isEnabled) {
        screenfull.toggle(wrapperElement.current);
      } else {
        setFullscreenFallbackEnabled(!fullscreenFallbackEnabled);
      }
    }
  };

  const ref = (player: ReactPlayer) => {
    setPlayerState(player);
  };

  function transformDailymotionUrl(inputUrl: string): string {
    const parts = inputUrl.split("/video/");

    if (parts.length !== 2) {
      throw new Error(
        "Invalid URL format. Please provide a URL in the format https://www.dailymotion.com/video/VIDEO_ID"
      );
    }

    const videoId = parts[1];

    const embedUrl = `https://www.dailymotion.com/embed/video/${videoId}?autoplay=1`;

    return embedUrl;
  }

  return (
    <Wrapper
      screenFullActive={"isFullscreen" in screenfull && screenfull.isFullscreen}
      fullscreenFallbackEnabled={fullscreenFallbackEnabled}
      ref={wrapperElement}
    >
      {source?.includes("rumble") ? (
        <iframe
          title="iframe"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={source}
          allowFullScreen
        ></iframe>
      ) : source?.includes("dailymotion") ? (
        <iframe
          title="Dailymotion Video Player"
          src={transformDailymotionUrl(source)}
          width="100%"
          height="100%"
          allowFullScreen
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            overflow: "hidden",
          }}
        ></iframe>
      ) : (
        <ReactPlayer
          ref={ref}
          className="react-player"
          width="100%"
          height="100%"
          onReady={handleReady}
          onStart={handleStart}
          onPlay={handlePlay}
          onPause={handlePause}
          onBuffer={() => console.log("onBuffer")}
          onBufferEnd={() => console.log("onBufferEnd")}
          onSeek={handleSeek}
          onEnded={handleEnded}
          onError={(e) => console.log("onError", e)}
          onProgress={handleProgress}
          onDuration={handleDuration}
          {...optionsState}
        />
      )}

      {showPlaceHolderImage &&
      placeHolderImage &&
      placeHolderImage?.length > 0 &&
      (source?.includes(".mp3") ||
        source?.includes(".wav") ||
        source === null) ? (
        <AudioPlaceholder
          showPlaceHolderImage={showPlaceHolderImage}
          placeHolderImage={placeHolderImage}
        />
      ) : null}

      {!optionsState.controls &&
      optionsState.muted &&
      !source?.includes("rumble") &&
      !source?.includes("dailymotion") &&
      (source?.includes(".mp3") || source?.includes(".wav")) ? (
        <Unmute
          text={"TAP TO UNMUTE"}
          onClick={() => {
            handlePlayPause();
            setTimeout(() => {
              unmuteClickHandler();
            }, 1000);
          }}
        />
      ) : null}

      {!optionsState.controls && !source?.includes("dailymotion") ? (
        <UI
          duration={optionsState.duration}
          playing={optionsState.playing}
          played={optionsState.played}
          loaded={optionsState.loaded}
          muted={optionsState.muted}
          volume={optionsState.volume}
          onFullscreen={handleClickFullscreen}
          onPlayPause={handlePlayPause}
          seekMouseDown={handleSeekMouseDown}
          seekChange={handleSeekChange}
          seekMouseUp={handleSeekMouseUp}
          toggleMute={handleToggleMuted}
          volumeChange={handleVolumeChange}
          isMp3={
            source?.includes(".mp3") ||
            source?.includes(".wav") ||
            source === null
          }
          controlsEnabled={controlsEnabled}
        />
      ) : null}
    </Wrapper>
  );
}

Player.defaultProps = defaultProps;

export default Player;
